<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="canEditActivity"
              variant="primary"
              :disabled="isAddRecordActive"
              :to="{ name: 'activity-configuration'}"
            >
              <span> {{ $t('ADD_HABIT') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>
          <!-- Search Game-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="competition"
                :options="allGames"
                label="c_string_swe"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_GAME')"
                @input="fetchAllActivities(competition.ID);changeCompetitionClick()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card :title="$t('HABIT_BEHAVIOUR')">
      <app-timeline
        v-for="(competitionTask,index) in allActivities"
        :id="competitionTask.task.taskID.toString()"
        :key="competitionTask.task.taskID"
      >
        <!-- Sub task-->
        <app-timeline-item
          :id="competitionTask.task.taskID.toString()"
          :key="index"
          variant="primary"
          icon="FileTextIcon"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6> <b-avatar
              size="30"
              :text="competitionTask.sortOrder.toString()"
              variant="light-primary"
            />{{ competitionTask.task.taskbeskrivning }} ( {{ competitionTask.task.points }})

            </h6>
            <div class="text-nowrap">
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${competitionTask.task.taskID}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer mx-1"
                size="16"
                @click="$router.push({ name: 'activity-configuration-id', params: { id: competitionTask.task.taskID }})"
              />
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${competitionTask.task.taskID}-send-icon`"
                icon="PlusIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="navigateSubActivityConfiguration(competitionTask.task.taskID,0)"
              />
              <!-- <b-form-checkbox
                id="accountSwitch2"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
              /> -->
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${competitionTask.task.taskID}-send-icon`"
                icon="MonitorIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="navigateMediaConfiguration(competitionTask.comp_id,competitionTask.task.taskID,0)"
              />
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${competitionTask.task.taskID}-send-icon`"
                icon="Trash2Icon"
                class="cursor-pointer mr-1"
                size="16"
                @click="deleteTask(competitionTask)"
              />
            </div>
          </div>
          <p v-html="competitionTask.task.taskdescription" />
          <b-collapse
            :id="competitionTask.task.taskID.toString()"
            visible
          >
            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                v-for="(subtask,i) in competitionTask.task.task_subtasks"
                :id="subtask.subtask.subtaskID.toString()"
                :key="i"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span> <b-avatar
                  size="30"
                  :text="subtask.sortorder.toString()"
                  variant="light-warning"
                />{{ subtask.subtask.subtaskstring }} ({{ subtask.subtask.subtaskpoint }})  </span>
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      v-if="canEditActivity"
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="navigateSubActivityConfiguration(competitionTask.task.taskID,subtask.subtask.subtaskID)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.EDIT') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateMediaConfiguration(competitionTask.comp_id,0,subtask.subtask.subtaskID)">
                    <feather-icon icon="MonitorIcon" />
                    <span class="align-middle ml-50">{{ $t('MEDIA') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeSubTask(subtask)">
                    <feather-icon icon="Trash2Icon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>
  </div>
</template>

<script>

import {
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BCard,
  BCollapse,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mapActions, mapState } from 'vuex'
import useActivityList from './useActivityList'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { canEditDeleteGame } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      competition: useJwt.getGame(),
      canEditActivity: true,
    }
  },
  setup() {
    const {
      blankTask,
      blankCompetitionTask,
      blankSubTask,
    } = useActivityList()

    const {
      successMessage, errorMessage,
    } = mixinAlert()

    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const subTask = ref(JSON.parse(JSON.stringify(blankSubTask)))
    const competitionTask = ref(JSON.parse(JSON.stringify(blankCompetitionTask)))
    const isHabitHandlerSidebarActive = ref(false)
    const isBehaviourHandlerSidebarActive = ref(false)
    const isAddRecordActive = ref(true)

    return {
      competitionTask,
      isHabitHandlerSidebarActive,
      isBehaviourHandlerSidebarActive,
      isAddRecordActive,
      task,
      subTask,
      blankTask,
      blankSubTask,
      successMessage,
      errorMessage,
    }
  },
  created() {
    this.changeCompetitionClick()
    this.fetchAllGames()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('activity', ['allActivities']),
    ...mapState('game', ['allGames']),
  },
  mounted() {
    this.fetchAllGames().then(() => {
      if (router.currentRoute.params.id) {
        this.competition = this.allGames.find(i => i.encrypt_id === router.currentRoute.params.id)
        if (this.competition) {
          this.changeCompetitionClick()
        }
      }
    })
  },
  methods: {
    ...mapActions('activity', ['fetchAllActivities', 'deleteActivity', 'deleteSubActivity']),
    ...mapActions('game', ['fetchAllGames']),
    deleteTask(taskData) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${taskData.task.taskbeskrivning}  ${this.$i18n.t('HABIT')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.setCompetitionTask(taskData)
            this.onDeleteTask(this.task)
          }
        })
    },
    navigateSubActivityConfiguration(taskID, subTaskID) {
      const id = taskID
      const subtaskId = subTaskID
      this.$router.push({
        name: 'subactivity-configuration-id',
        params: { id },
        query: { subtaskId },
      })
    },
    navigateMediaConfiguration(competitionID, tId, sId) {
      const id = competitionID
      const taskID = tId
      const subTaskID = sId
      this.$router.push({
        name: 'media-list-id',
        params: { id },
        query: { taskID, subTaskID, objectID: 'game' },
      })
    },
    onDeleteTask(taskData) {
      const payload = {
        competitionId: taskData.competitionID,
        taskId: taskData.taskID,
      }
      this.deleteActivity(payload).then(response => {
        if (response.data.success) {
          this.successMessage(this.$i18n.t('MESSAGE.HABIT_DELETED'))
          this.fetchAllActivities(taskData.competitionID)
        }
      }).catch(() => {
        this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_HABIT_EXIST'))
      })
    },
    onDeleteSubTask(subTaskData) {
      const payload = {
        taskId: subTaskData.taskid,
        subTaskId: subTaskData.subtask.subtaskID,
      }
      this.deleteSubActivity(payload).then(response => {
        if (response.data.success) {
          this.successMessage(this.$i18n.t('MESSAGE.BEHAVIOUR_DELETED'))
          this.fetchAllActivities(subTaskData.competitionID)
        }
      }).catch(() => {
        this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_BEHAVIOUR_EXIST'))
      })
    },
    removeSubTask(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.subtask.subtaskstring}  ${this.$i18n.t('BEHAVIOUR')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.competitionID = this.competition.ID
            this.onDeleteSubTask(data)
          }
        })
    },
    setCompetitionTask(taskData) {
      this.task = { ...taskData.task }
      this.task.competitionID = taskData.comp_id
      this.task.sortOrder = taskData.sortOrder
      this.competitionTask = { ...taskData }
    },
    changeCompetitionClick() {
      this.isAddRecordActive = false
      this.fetchAllActivities(this.competition.ID)
      useJwt.setGame(this.competition)
      this.canEditActivity = canEditDeleteGame(this.competition)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
